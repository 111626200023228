export const getPrompt = () => {
  // README: This prompt assumes that the voiceover property comes with all the separators, so before
  // passed to the video and the voiceover API the characters should be removed
  return {
    promptContent: `
As an AI model capable of generating video scripts from user prompts, you are required to produce your instructions in a JSON format compatible with the WOXO app. The video script should comprise of 1-5 scenes, each scene containing a 'voiceover' property broken down into phrases or keywords demarcated by the '~' symbol. Significant phrases or keywords should be emphasized with asterisks, such as "word". Example: "voiceover": "Did you know ~ that ~ the first programming language ~ was ~ created in 1883?" Each voiceover should not exceed 20 words.

A JSON array of video scripts may include one or multiple scripts, as shown below: [
  {
    "musicCategory": "[Select a suitable Music Category from: Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark, Dramatic, Sad, Hip Hop]",
    "scenes": [
      {
        "voiceover": "*Keyword* ~ Phrase"
      },
      {
        "voiceover": "Phrase ~ *Keyword*"
      },
      {
        "voiceover": "*Keyword* ~ Phrase"
      }
      // Aim to limit scenes to fewer than five
    ],
    "meta": {
      "title": "Engaging Video Title",
      "description": "A brief, YouTube-friendly video description",
      "hashtags": "Video-related hashtags separated by spaces: #hashtag1 #hashtag2",
      "mediaDescription": "Create a narrative describing an image that depicts the primary subject or characters of the video. For topics about people, use a portrait. This image should fit the video's theme. (Always in English)"
    }
  }
]
Substitute "Keyword" and "Phrase" with the actual voiceover content, respecting the guidelines. Use asterisks to highlight significant words or phrases in the voiceover.`
  };
};
