import axios from 'axios';
import { isObject } from '~/helpers/javascript';
import api from '~/services/api';
import { getAccessToken } from './../../../services/security.service';
import { API_VIDEO } from '../../../services/app.config';
import { validateFulfilled } from './helper';
import * as Sentry from '@sentry/nextjs';

export const check = ({ jobid }) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${API_VIDEO}/statuslambda?jobid=${jobid}`
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });

const status = (
  data,
  // limit: number,
  checkStatus
) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      check({ jobid: data.jobid })
        .then((response) => {
          checkStatus(response);
          if (response.totalRunning === 0) {
            clearInterval(interval);
            resolve(response.data);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }, 4000);
  });

export const generate = (
  json,
  // limit: number,
  checkStatus
) =>
  new Promise(async (resolve, reject) => {
    const _token = await getAccessToken();
    api({
      url: `${API_VIDEO}/generatelambda`,
      method: 'POST',
      data: { wait: false, json, _token, from: 'idea2video', tool: 'idea-to-video' }
    })
      .then((response) => Promise.all([response.data.jobid, status(response.data, checkStatus)]))
      .then(([jobid, data]) => {
        resolve({
          data: data.map((v) => ({
            // TODO: See if this can be used
            outPath: v.outPath,

            // a partir de aki es cool
            index: v.editSpec.index,
            jobid: v.jobid,
            id: v._id,
            generationDate: v.updated_at,
            videoUrl: v.videoEndpoint,
            videoDownloadUrl: v.downloadLink,
            thumbnailUrl: v.thumbnailsLink,
            gifUrl: v.previewLink,
            landingPage: v.landing,
            width: v.editSpec.width,
            height: v.editSpec.height,
            title: v.editSpec.title,
            status: v.status || null,
            error: v.error || { errorMessage: v.errorMessage } || null
          })),
          jobid
        });
      })
      .catch((e) => {
        reject(e);
      });
  });

//handle download
const statusDownload = (downloadJobId) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      axios({
        url: `${API_VIDEO}/statusdownload?downloadJobId=${downloadJobId}`
      })
        .then((response) => {
          if (response.data.status === 'done') {
            clearInterval(interval);
            resolve(response.data);
          } else if (response.data.status === 'fail') {
            throw response.data.errors;
          }
        })
        .catch((e) => {
          clearInterval(interval);
          reject(e);
        });
    }, 4000);
  });

export const generateDownloadVideoZipOrder = async (jobid) => {
  return axios({
    url: `${API_VIDEO}/download?jobid=${jobid}`
  })
    .then((response) => statusDownload(response.data.downloadJobId))
    .catch((e) => {
      console.error(e);
    });
};

const upload = (file) =>
  new Promise((resolve, reject) => {
    if (!file) {
      alert('!File, Url must not be empty');
      reject();
    }

    const cldEndpoint = 'https://api.cloudinary.com/v1_1/dakp804eh/auto/upload';

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'first_preset');
    formData.append('folder', 'woxo-videos-user-gallery/_dreaming');

    axios(cldEndpoint, {
      method: 'POST',
      data: formData
    })
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          // alert("Elements must be an array");
          reject();
        }
      })
      .catch(() => {
        // alert("Ups fail to load elements");
        reject();
      });
  });

export const uploadMedias = (medias) =>
  new Promise((resolve) => {
    Promise.allSettled(
      medias.map((m) => {
        return Promise.allSettled(
          m.medias.map((a) => {
            if (typeof a === 'object') return upload(a);
            else {
              return new Promise((res, rej) => {
                res({ url: a });
              });
            }
          })
        );
      })
    ).then((data) => {
      let _data = [];
      data.forEach((d, i) => {
        const fulfilled = validateFulfilled(d.value);
        if (fulfilled) {
          let _value = [];
          d.value.forEach((v) => {
            _value.push(v.value.url);
          });
          _data[i] = {
            status: 'fulfilled',
            value: _value
          };
        } else {
          _data[i] = {
            status: 'rejected',
            value: []
          };
        }
      });

      let _medias = [];
      medias.map((m, i) => {
        let _media = { id: m.id, medias: _data[i].value, status: _data[i].status };
        _medias.push(_media);
      });

      resolve(_medias);
    });
  });

export const checkLimit = () =>
  new Promise((resolve) => {
    api({
      method: 'POST',
      url: `${API_VIDEO}/check`
    })
      .then((response) => {
        if (isObject(response.data) && response.data) {
          resolve(response.data);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });
